import { FallbackProps } from 'react-error-boundary';

const ERROR_STYLE = 'color:red';

function Fallback({ error }: Readonly<FallbackProps>) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre className={ERROR_STYLE}>{error.message}</pre>
        </div>
    );
}

export { Fallback };
