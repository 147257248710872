import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { dateTimeReviver } from './dateTimeReviver';

export const contentApi = axios.create({
    baseURL: '/',
});

export type useFetchProps = {
    config?: AxiosRequestConfig;
};

export type useAuthAxiosResponse = {
    axiosInstance: AxiosInstance;
};

const jsonInterceptor = [
    (response: AxiosResponse<unknown>) => {
        const contentType = response.headers['content-type'];
        if (contentType === undefined) return response;
        if (!/json/gi.test(contentType)) {
            /** not json, like binary string */
            return response;
        }
        if (response?.data) {
            const str = JSON.stringify(response.data);
            response.data = JSON.parse(str, dateTimeReviver);
            return response;
        } else {
            return response;
        }
    },
    (error: unknown) => {
        console.error('API request failed:', error);
        // You could add custom error handling here
        return Promise.reject(new Error(String(error)));
    },
];

contentApi.interceptors.response.use(...jsonInterceptor);

const useAuthAxios = (): useAuthAxiosResponse => {
    return { axiosInstance: contentApi };
};

export { useAuthAxios };
