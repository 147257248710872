import { dateDefaultOptions, timeDefaultOptions } from '@config/dateTimeDefaults';

const DEFAULT_LOCALE = 'en-AU';

const validateDate = (date: unknown): void => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
        throw new Error('Invalid date object');
    }
};

export const formatAsDefaultDate = (date: Date, locale: string = DEFAULT_LOCALE): string => {
    validateDate(date);
    return date.toLocaleDateString(locale, dateDefaultOptions);
};

export const formatAsDefaultTime = (date: Date, locale: string = DEFAULT_LOCALE): string => {
    validateDate(date);
    return date.toLocaleTimeString(locale, timeDefaultOptions);
};

export const formatAsDefaultDateTime = (date: Date, locale: string = DEFAULT_LOCALE): string => {
    validateDate(date);
    return `${formatAsDefaultDate(date, locale)} - ${formatAsDefaultTime(date, locale)}`;
};
